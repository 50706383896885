// COLORS
/* Verde Rubeus */
$greenRubeus: #0DA6A6;
$lightGreenRubeus: #3DD5D6;
$darkGreenRubeus: #004747;

/* Vermelho Rubeus */
$redRubeus: #FF0031;
$lightRedRubeus: #F0758D;
$darkRedRubeus: #99001D;

/* Cinza Rubeus */
$greyRubeus: #4D4D4D;
$veryLightGreyRubeus: #A2A2A2;
$lightGreyRubeus: #808080;
$darkGreyRubeus: #1A1A1A;

/* Sucesso Rubeus */
$successRubeus: #23BF6A;
/* Alerta Rubeus */
$alertRubeus: #FFB946;
/* Perigo Rubeus */
$dangerRubeus: #F7685B;
// END COLORS
