
.ps-custom-select-container-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
}

.ps-icon-hidden {
    display: none !important;;
}

input.ps-auto-complete-input.form-control::-webkit-input-placeholder {
    opacity: 1;
    color: #4e4d4d;
    white-space: nowrap;
    max-width: 94%;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    text-overflow: ellipsis;
}

.ps-input-autocomplete-filter-clear {
    position: relative;
    i {
        position: absolute;
        display: block;
        top: 18px;
        right: 15px;
        width: 15px;
        height: 0px;
        font-size: 20px;
        cursor: pointer;
        color: #4e4d4d;
    }
    input {
        padding-right: 16px;
        box-sizing: border-box;
    }
}



.ps-custom-select-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  color: #4e4d4d;
  font-size: inherit;
  line-height: 1.5;
}

.ps-comp-select-container-column {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
}

.form-check-input-select{
    cursor: pointer;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    pointer-events: none;
}

.ps-auto-complete-option-item-multiple {
    color: black !important;
    display: flex;
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background: #0000001a
    }
}

.ps-auto-complete-option-item {
    color: black !important;
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background: #0000001a
    }
}

.ps-auto-complete-no-option-item {
    padding: 15px;
    font-size: 16px;
}

.ps-auto-complete-inside-input-container {
    position: relative;
}


.ps-auto-complete-option-container-closed {
    display: none;
}

.ps-auto-complete-option-category-hidden {
    .ps-auto-complete-option-category {
        display: none;
    }
}

.ps-auto-complete-option-category {
    color: #808080 !important;
    padding: 20px;
    font-size: 15px;
    font-weight: 500;
    font-family: Poppins;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ps-auto-complete-option-container {
    position: absolute;
    background: white;
    max-height: 200px;
    overflow: auto;
    width: 100%;
    z-index: 2000;
    -webkit-box-shadow: 0px 1px 2px 1px rgb(175, 174, 174);
    -moz-box-shadow: 0px 1px 2px 1px rgb(175, 174, 174);
    box-shadow: 0px 1px 2px 1px rgb(175, 174, 174);
}

.ps-auto-complete-input-container {
    position: relative;
}

.ps-auto-complete-loader-activated {
    pointer-events: none;
    opacity: 0.75;
}

