@use '@angular/material' as mat;
@import '../scss/variables';

.rb-dialog-container > .mdc-dialog__container {
  padding: 0px;
  border-radius: 0px;
  overflow: hidden;
}

.rb-dialog-container > .mdc-dialog__container {
  height: auto;
}

.rb-dialog-container .mat-mdc-dialog-title {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
}

.rb-dialog-container .mat-mdc-dialog-title:not(.rb-warning) {
  color: mat.get-color-from-palette($rb-primary, 500);
  background-color: mat.get-color-from-palette($rb-primary, 50);
}
.rb-dialog-container .mat-mdc-dialog-title.rb-warning {
  color: mat.get-color-from-palette($rb-warn, 500);
  background-color: mat.get-color-from-palette($rb-warn, 50);
}

.rb-dialog-container .mat-mdc-dialog-title h2 {
  margin: 0px;
  margin-top: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

}

.rb-dialog-closeButtonContainer{
  padding: 6px;
}
.rb-dialog-titleContainer {
  padding: 6px 16px;
}

.rb-dialog-closeButtonContainer .mat-mdc-icon-button .mat-icon {
  line-height: 24px;
  font-size: 20px;
}

.rb-dialog-actions.mat-mdc-dialog-actions {
  padding: 8px 24px;
  border-top: 1px solid #E0E0E0;
}


.rb-dialog-container.rb-login-dialog {
  .rb-dialog-actions{
    border-top: 1px solid transparent;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-mdc-dialog-content.rb-dialog-content.mat-mdc-dialog-content {
  max-height: calc(100% - 127px);
  height: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-mdc-dialog-content.rb-dialog-content.mat-mdc-dialog-content.rb-has-overflow {
  padding: 0 10px 0px 24px;
  overflow-x: hidden;
}

.rb-default-dialog {
  height: 100%;

  mat-horizontal-stepper.RbHorizontalStepper.mat-stepper-horizontal.ng-tns-c7-3.mat-stepper-label-position-end {
    position: relative;
    height: 100%;
  }

  .mat-horizontal-content-container {
    overflow: auto !important;
    height: 100%;
    display: block;
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 65vh;
  }
}

.mat-mdc-dialog-actions.rb-dialog-actions {
  margin-bottom: 0px;
}
