// classes utilizadas no componente de upload, esse arquivo vai estar importado no styles.scss
@use '@angular/material' as mat;
@import '../../../../scss/variables';

.ps-comp-upload-container-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
}
.ps-comp-upload-container-column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.ps-comp-upload-container-chips {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.ps-comp-upload-container-flex {
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  // justify-content: flex-end;
    align-items: center;
}

.ps-file-chip {
  width: fit-content;
  background: #E0E0E0;
  padding: 5px;
  margin: 5px;
  border-radius: 15px;
}

.hidden-file-item {
  visibility: hidden;
  width: 0;
  max-width: 0;
  overflow: hidden;
  display: inline-flex;
}

.uploadStatusTooltip {
    position: relative;
    display: inline-block;
}

.uploadStatusTooltip .uploadStatusTooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.uploadStatusTooltip .uploadStatusTooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.uploadStatusTooltip:hover .uploadStatusTooltipText {
  visibility: visible;
  opacity: 1;
}

.ps-comp-upload-button {
  padding: 0px !important;
  margin-bottom: 0 !important;
  display: inline-flex !important;
  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
  i {
    color: #707683;
  }
}

.file-error {
  padding: 5px;
  margin: 5px;
}

.ps-comp-upload-icon-type-default {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  background: #D4D4D4;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.ps-comp-upload-icon-type-default-on {
  background: mat.get-color-from-palette($rb-primary, 500) !important;
}

.ps-comp-upload-icon-type-approved {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  background: #ACEAC8;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.ps-comp-upload-icon-type-refused {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  background: #FFEAC7;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.ps-comp-upload-icon-type-in-analysis {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  background: mat.get-color-from-palette($rb-primary, 500);
  border-radius: 50%;
  transition: background 0.3s ease;
}

.ps-comp-upload-description {
  margin: 0 10px;
  font-family: poppins;
  font-size: 0.9rem;
  color:#707683;
  display: flex;
  i {
    margin-left: 5px;
    font-size: 20px;
  }
}

.ps-comp-upload-status-name {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  margin-left: 42px;
  padding: 8px;
  border-radius: 9px;
  background: #EAEAEA;
  color: rgba(0, 0, 0, 0.6);
}

.ps-comp-upload-status-name-approved {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  margin-left: 42px;
  padding: 8px;
  border-radius: 9px;
  background: #ACEAC8;
  color: rgba(0, 0, 0, 0.6);
}

.ps-comp-upload-status-name-refused {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  margin-left: 42px;
  padding: 8px;
  border-radius: 9px;
  background: #FFEAC7;
  color: rgba(0, 0, 0, 0.6);
}

.ps-comp-upload-tooltip {
  cursor: pointer;
  margin-left: 10px;
  position: relative;

  &:after {
    content: attr(data-text);
    position: absolute;

    top: 50%;
    transform: translateY(-50%);

    left:100%;
    margin-left:15px;

    width:200px;
    padding:10px;

    font-family: 'Roboto';
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 129.2%;
    background: #fff;
    color: #818181;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14),
      0px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    z-index: 1;

    display:none;
  }
}

.ps-comp-upload-tooltip.left:after {
  left:initial;
  margin:initial;

  right:100%;
  margin-right:15px;
}

.ps-comp-upload-tooltip:hover:after {
  display:block;
}

.ps-comp-upload-flex-hidden-desktop {
  display: none;
}


@media (max-width: 600px) {
  .ps-comp-upload-container-flex-hidden-mobile {
    display: none;
  }
  .ps-comp-upload-flex-hidden-desktop {
    display: flex;
  }
  .ps-comp-upload-container-row {
    flex-direction: row;
  }
  // .ps-comp-upload-container-flex-mobile {
  //   justify-content: flex-end;
  // }
  .ps-comp-upload-container-flex-order-1{
    order: 1;
  }
  .ps-comp-upload-container-flex-order-2{
    order: 2;
  }
  .ps-comp-upload-container-flex-order-3{
    order: 3;
  }
  .ps-comp-upload-button {
    margin-top: 0px;
  }
}
