

.rb-padding-30x30 {
  padding: 30px;
}

.rb-padding-14x0 {
  padding: 14px 0px;
}

.rb-padding-10x0 {
  padding: 10px 0px;
}

.rb-padding-20x0 {
  padding: 20px 0px;
}

.rb-padding-0x5 {
  padding: 0px 5px;
}

.rb-padding-bottom-60 {
  padding-bottom: 60px;
}

.rb-padding-bottom-30 {
  padding-bottom: 30px;
}

.rb-padding-bottom-10 {
  padding-bottom: 10px;
}

.rb-padding-top-10 {
  padding-top: 10px;
}

.rb-padding-top-20 {
  padding-top: 20px;
}

.rb-padding-top-24 {
  padding-top: 24px;
}

.rb-padding-24x24 {
  padding: 24px 24px; 
}

.rb-padding-0x24 {
  padding: 0px 24px; 
}

.rb-padding-24x0 {
  padding: 24px 0px; 
}
.rb-padding-14x24 {
  padding: 14px 24px;  
}
.rb-padding-30x20 {
  padding: 30px 20px;  
}

.rb-margin-right-5{
  margin-right: 5px;
}

.rb-padding-right-30{
  padding-right: 30px;
}

.rb-padding-5x30x5x0{
  padding: 5px 30px 5px 0px;
}

.rb-padding-30x0x5{
  padding: 30px 0px 5px;
}

.rb-padding-5x24 {
  padding: 5px 24px;
}

.rb-padding-right-5 {
  padding-right: 5px;
}