.container-direction-row {
  flex-flow: row wrap;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}
.container-direction-column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}
.container-layout-none-none, .container-layout-start-none {
  place-content: stretch flex-start;
  align-items: stretch;
}
.container-layout-center-none {
  place-content: stretch center;
  align-items: stretch;
}
.container-layout-end-none {
  place-content: stretch flex-end;
  align-items: stretch;
}
.container-layout-space-around-none {
  place-content: stretch space-around;
  align-items: stretch;
}
.container-layout-space-between-none {
  place-content: stretch space-between;
  align-items: stretch;
}
.container-layout-space-evenly-none {
  place-content: stretch space-evenly;
  align-items: stretch;
}
.container-layout-none-start, .container-layout-start-start {
  place-content: flex-start;
  align-items: flex-start;
}
.container-layout-none-center, .container-layout-start-center {
  place-content: center flex-start;
  align-items: center;
}
.container-layout-none-end, .container-layout-start-end {
  place-content: flex-end flex-start;
  align-items: flex-end;
}
.container-layout-none-stretch {
  place-content: stretch flex-start;
  align-items: stretch;
  max-height: 100%;
}
.container-layout-center-start {
  place-content: flex-start center;
  align-items: flex-start;
}
.container-layout-end-start {
  place-content: flex-start flex-end;
  align-items: flex-start;
}
.container-layout-space-around-start {
  place-content: flex-start space-around;
  align-items: flex-start;
}
.container-layout-space-betweem-start {
  place-content: flex-start space-between;
  align-items: flex-start;
}
.container-layout-space-evenly-start {
  place-content: flex-start space-evenly;
  align-items: flex-start;
}
.container-layout-center-center {
  place-content: center;
  align-items: center;
}
.container-layout-end-center {
  place-content: center flex-end;
  align-items: center;
}
.container-layout-space-around-center {
  place-content: center space-around;
  align-items: center;
}
.container-layout-space-betweem-center {
  place-content: center space-between;
  align-items: center;
}
.container-layout-space-evenly-start {
  place-content: center space-between;
  align-items: center;
}
.container-layout-center-end {
  place-content: flex-end center;
  align-items: flex-end;
}
.container-layout-end-end {
  place-content: flex-end;
  align-items: flex-end;
}
.container-layout-space-around-end {
  place-content: flex-end space-around;
  align-items: flex-end;
}
.container-layout-space-betweem-end {
  place-content: flex-end space-between;
  align-items: flex-end;
}
.container-layout-space-evenly-end {
  place-content: flex-end space-evenly;
  align-items: flex-end;
}
.container-layout-start-stretch {
  place-content: stretch flex-start;
  align-items: stretch;
  max-height: 100%;
}
.container-layout-center-stretch {
  place-content: stretch center;
  align-items: stretch;
  max-height: 100%;
}
.container-layout-end-stretch {
  place-content: stretch flex-end;
  align-items: stretch;
  max-height: 100%;
}
.container-layout-space-around-stretch {
  place-content: stretch space-around;
  align-items: stretch;
  max-height: 100%;
}
.container-layout-space-betweem-stretch {
  place-content: stretch space-between;
  align-items: stretch;
  max-height: 100%;
}
.container-layout-space-evenly-stretch {
  place-content: stretch space-evenly;
  align-items: stretch;
  max-height: 100%;
}

.container-layout-space-between-stretch {
  place-content: stretch space-between;
  align-items: stretch;
  max-height: 100%;
}

.container-layout-space-evenly-center {
  place-content: center space-evenly;
  align-items: center;
}

.container-layout-space-between-end {
  place-content: flex-end space-between;
  align-items: flex-end;
}

.container-layout-space-between-center {
  place-content: center space-between;
  align-items: center;
}

.container-layout-space-between-start {
  place-content: flex-start space-between;
  align-items: flex-start;
}

.ps-defalt-icon-size {
  font-size: 16px;
}

.flatpickr-calendar {
  position: fixed !important;
}


.ps-input-hidden {
  display: none;
}

.ps-editor-input-hidden {
  display: none;
}

.choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: none !important;
  background-color: #fff;
  margin: 0;
}

.choices__item {
  font-size: 16px !important;
}

.choices__input {
  display: block;
  width: 100%;
  padding: 15px !important;
  font-size: 14px !important;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}

.choices__list--dropdown {
  visibility: inherit;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
  border: none !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

formio {
  .choices__list--dropdown {
    display: none;
    visibility: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility;
    background-color: #fff;
    .choices__list {
      position: relative;
      max-height: 200px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      will-change: scroll-position;
    }
  }

  .choices__list--dropdown.is-active {
    visibility: visible;
    display: block;
  }
  .choices__list--dropdown .choices__item--selectable {
    padding: 15px;
  }

  div[ref="prefix"],
  div[ref="suffix"] {
    padding: 10px;
    background-color: #f5f5f5;
    border: none;
  }

  div.ps-button {
    display: flex;
    // width: 100%;
  }

  button.ps-button {
    height: fit-content;
    margin: auto;
  }

  .formio-hidden {
    display: none;
  }

  .formio-component.has-error .invalid-feedback,
  .formio-component.has-message .invalid-feedback {
    display: block;
    margin-top: 0px;
  }

  .invalid-feedback {
    // position: absolute;
    display: none;
    width: 100%;
    color: transparent;
  }

  .formio-error-wrapper {
    color: #dc3545;
    background-color: transparent;
    border-color: transparent;
  }

  .formio-warning-wrapper {
    color: #dfee10;
    background-color: transparent;
    border-color: transparent;
  }

  .formio-errors .error {
    color: #dc3545;
    margin-top: 0px;
  }

  .formio-error-wrapper,
  .formio-warning-wrapper {
    padding: 0px;
  }

  .form-group {
    .form-check-inline .form-check-input {
      position: absolute;
      margin-top: 0;
      top: 2px;
      left: 1px;
      margin-right: .3125rem;
      margin-left: 0;
      height: 17px;
      width: 24px;
    }

    .form-check-label {
      position: relative;
      color: #58524d;
    }

    margin-bottom: 1rem;

    // position: relative;
    .col-form-label {
      padding-top: calc(.375rem + 1px);
      padding-bottom: calc(.375rem + 1px);
      margin-bottom: 0;
      color: #4e4d4d;
      font-size: inherit;
      line-height: 1.5;
    }

    // .form-control:hover{
    //   border-bottom: 1.75px solid #8f8a8a;
    // }
    .form-control:focus {
      // color: #495057;
      // background-color: none;
      // color: transparent;
      // border-color: transparent;
      // outline: 0;
      box-shadow: 0 0 0 0rem transparent !important;
      // border-bottom: 1.75px solid $greenRubeus;
    }

    .form-control {
      // border: 1px solid #ced4da;
      // border-radius: .25rem;
      // border: none;
      // border-radius: 0;
      // border-bottom: 1.75px solid #b2aaaa;
      // display: block;
      // width: 100%;
      // padding: .375rem .75rem;
      // font-size: 1rem;
      line-height: 2.5;
      padding: 9px;
      color: #495057;
      background-color: rgba(0, 0, 0, .04);
      // background-clip: padding-box;
      // transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .form-check-label {
      color: rgb(88, 82, 77);
    }

    .radio label input[type=radio],
    label.radio-inline input[type=radio] {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 19px;
      height: 100%;
      margin: 0;
      overflow: hidden;
      pointer-events: none;
      opacity: 1;
    }

    .checkbox label input[type=checkbox],
    label.checkbox-inline input[type=checkbox] {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 19px;
      height: 100%;
      min-height: 19px;
      margin: 0;
      overflow: hidden;
      // pointer-events: none;
      opacity: 1;
    }

    // .form-control:disabled, .form-control[readonly]{
    //   background-color: #e9ecef;
    //   opacity: 1;
    // }
  }
}
