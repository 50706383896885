/* cor padrao rubeus */
@import './assets/css/color';
@import './scss/_variables.scss';
@import './styles/Dialogs.scss';
@import './styles/RbPaddings.scss';
@import 'src/form-theme.scss';
@import 'src/scopedBootstrap.scss';
@import 'src/app/shared/components/upload/upload-type-classes.scss';
@import 'src/app/shared/components/lgpd/lgpd.scss';
@import 'src/app/shared/components/custom-select/custom-select-type.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';


:root {
  --rb-primary: $rb-primary;
  --rb-accent: $rb-accent;
  --rb-warn: $rb-warn;
  --institutional-color: #009688;
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-divider-color: #e0e2ec;
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--rb-primary-theme-100);
  --mdc-outlined-button-label-text-tracking: normal;
  --mat-table-row-item-container-height: 48px;
  --rb-primary-theme-contrast-400: white;

}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 36px;
}

html .mat-mdc-mini-fab.mat-primary{
  --mat-fab-small-foreground-color: var(--rb-primary-theme-contrast-500,inherit);
}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

.vlibras-hidden {
  display: none;
}

.ps-body-admin {
  background: #F0F0F0;
}

a:hover {
  text-decoration: none;
}

/* padrão de card */
.mat-mdc-card {
  padding: 0 !important;
  border-radius: 0 !important;

  mat-card-content {
    padding: 24px !important;
  }
}

.ps-toolbar-view {
  max-height: 200px;
  overflow: auto;
  display: block !important;
}

/* padrão das linhas */
.mat-ink-bar {
  height: 3px !important;
}

/* padding pra quando for usar o tab e tiver dentro de card */
.ps-tab-mt-card {
  padding-top: 40px !important;
}

.ps-form-builder-content-link-preview-noClick {
  a {
    pointer-events: none;
  }
}

mat-form-field .mat-mdc-form-text-infix {
  width: auto !important;
}

.ps-fullScreenDialog {
  .mat-mdc-dialog-container {
    border-radius: 0;
  }
}

/* background padrão toggle */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-bar {
  background: rgba(34, 31, 31, 0.26);
}

.mat-mdc-outlined-button {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-content {
  font-weight: normal;
  font-size: 15px;
  font-size: 0.938rem;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #808080;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: (var(--rb-primary-theme-200));
}

.ps-label-slide-toggle {
  display: block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.ps-label-radio {
  display: block;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4D4D4D;
}

.ps-row {
  margin-bottom: 20px !important;
}

.ps-row-double {
  margin-bottom: 40px !important;
}

.ps-divider-container {
  margin: 26px 0;
}

.ps-visual-ide-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 100%;
  background: #fff !important;
}

.ps-ultima-alteracao-info {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.025em;
  color: #A2A2A2;
}

.ps-input-opcional {
  color: #0000004d !important;
}

.center {
  text-align: center;
}

.ps-title-extra-large {
  font-family: poppins,
  sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 60px;
  font-size: 3.75rem;
  color: $greyRubeus;
}

.ps-title-large {
  font-family: poppins,
  sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-size: 1rem;
  color: $greyRubeus;
}

.ps-title-medium {
  font-family: poppins,
  sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  font-size: 1.00rem;
  color: $greyRubeus;
  font-weight: 500;
}

.ps-title-info-large {
  font-family: poppins,
  sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  font-size: 1.37rem;
  color: $greyRubeus;
}

.ps-info {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: $lightGreyRubeus;
}

.ps-reading-15 {
  line-height: 154.2%;
  letter-spacing: 0.025em;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: $greyRubeus;
}

.ps-description-14 {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  letter-spacing: 0.025em;
  color: $veryLightGreyRubeus;
}

.ps-link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  letter-spacing: 0.025em;
  text-decoration-line: underline;
  color: $greenRubeus;
  cursor: pointer;
}

.ps-tooltip-button {
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ps-tooltip-content {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  color: #818181 !important;
  background: #fff;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12),
  0px 8px 10px rgba(0, 0, 0, 0.14),
  0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 300px !important;
}

.ps-tooltip-preLined-content {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.2%;
  display: flex;
  align-items: center;
  text-align: initial;
  letter-spacing: 0.025em;
  color: #818181 !important;
  background: #fff;
  white-space: pre-line;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12),
  0px 8px 10px rgba(0, 0, 0, 0.14),
  0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.pulse {
  overflow: visible !important;
  position: relative !important;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 60%;
    height: 60%;
    top: 8px;
    left: 8px;
    background-color: #ffb946a3;
    border-radius: inherit;
    -webkit-transition: opacity .3s,
    -webkit-transform .3s;
    transition: opacity .3s,
    -webkit-transform .3s;
    transition: opacity .3s,
    transform .3s;
    transition: opacity .3s,
    transform .3s,
    -webkit-transform .3s;
    -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
  }
}

.mat-mdc-raised-button {
  transition: background 0s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.ps-mat-list-disabled {
  pointer-events: none;

  & mat-slide-toggle {
    opacity: .38 !important;
  }
}

.mat-mdc-button[disabled],
.mat-mdc-unelevated-button[disabled],
.mat-mdc-icon-button[disabled],
.mat-mdc-outlined-button[disabled] {
  cursor: default;
  pointer-events: none;
}

.ps-badge {
  font-size: 13px;
  line-height: 12px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  border-radius: 16px;
  padding: 8.5px 13.5px;
  background: $successRubeus;
}

.ps-status-color-green {
  color: $successRubeus;
}

.ps-status-color-yellow {
  color: $alertRubeus;
}

.ps-status-color-lightGrey {
  color: $lightGreyRubeus;
}

.ps-status-color-red {
  color: $dangerRubeus;
}

.ps-count-changes {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $alertRubeus;
  color: #fff;
  min-width: 15px;
  min-height: 15px;
  line-height: 10px;
  border-radius: 50%;
  padding: 2%;
  text-align: center;
  font-size: 12px;
  margin-right: 8px;
}

.ps-save-edt-info {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.025em;
  color: #A2A2A2;
}

@-webkit-keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

.ps-step-builder-overlay-options {
  bottom: unset !important;
  top: 108px;
}

.rbPadding0x15 {
  padding: 0px 15px;
}

.rbPadding20x20 {
  padding: 20px;
}

.rbPadding5x0 {
  padding: 5px 0px;
}

.rbPadding0x20 {
  padding: 0px 20px;
}

.rb-white-form-field .mat-mdc-text-field-wrapper{
  background-color: transparent !important;
}
.rb-white-form-field .mdc-floating-label,
.rb-white-form-field .mat-mdc-select-arrow,
.rb-white-form-field .mat-mdc-select,
.rb-white-form-field .mdc-line-ripple{
  color: white !important;
}
.rb-white-form-field .mdc-list-item--selected,
.rb-white-form-field .mat-pseudo-checkbox-checked::after,
.rb-white-form-field .mdc-list-item__primary-text,
.rb-white-form-field .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: var(--mdc-list-list-item-label-text-color) !important;
}
.rb-white-form-field .mdc-line-ripple::before,
.rb-white-form-field .mdc-line-ripple::after
{
  background-color: white !important;
  border-bottom-color: white !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard .mdc-line-ripple,
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard .mat-form-field-ripple,
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard.mat-focused .mdc-line-ripple,
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard.mat-focused .mat-form-field-ripple {
  background-color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard .mat-mdc-floating-label,
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard.mat-focused .mat-mdc-floating-label {
  color: white;
}

.rb-white-form-field.hasBackground.mat-form-field-appearance-standard .mat-mdc-input-element {
  caret-color: white;
  color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard .mat-select-value {
  color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.rb-white-form-field.hasBackground.mat-form-field-appearance-standard .mat-select-arrow {
  color: white;
}

button.rb-white-button.mat-mdc-button.mat-mdc-button-base {
  color: #fff;
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0 !important;
}

.mdc-dialog__surface {
  overflow-y: hidden !important;
}

.ps-tab-mat-group-system-setting .mat-mdc-tab .mdc-tab__text-label,
.ps-tab-portal .mat-mdc-tab .mdc-tab__text-label {
  color: #858585 !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;
}

.ps-tab-mat-group-system-setting .mat-mdc-tab:not(.mdc-tab--stacked),
.ps-tab-portal .mat-mdc-tab:not(.mdc-tab--stacked) {
  height: 40px !important;
  min-width: 160px !important;
}

.mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, .6) !important;
}

.mdc-form-field > label {
  color: gray !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}

.mat-mdc-raised-button {
  letter-spacing: normal !important;
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  letter-spacing: normal !important;
}

.mat-mdc-outlined-button > .mat-icon {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mat-mdc-button > .mat-icon {
  margin-right: 0 !important;
  margin-left: 0 !important;
}


.ps-sp-step-tabs-content .mat-mdc-tab .mdc-tab__text-label,
.ps-sp-feed-tabs-content .mat-mdc-tab .mdc-tab__text-label {
  color: #FFF !important;
  opacity: 0.5 !important;
}

.ps-sp-step-tabs-content .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.ps-sp-feed-tabs-content .mat-mdc-tab .mdc-tab__text-label {
  color: #FFF !important;
  opacity: 1 !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border: none !important;
}

.ps-step-builder-tab-group .mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.ps-step-builder-tab-group .mat-mdc-tab-header-pagination {
  display: none !important;
}

.ps-svps-field-action-config {
  padding: 0 !important;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) !important;
  border-bottom-width: var(--mat-table-row-item-outline-width, 1px) !important;
  border-bottom-style: solid !important;
  letter-spacing: var(--mat-table-row-item-label-text-tracking) !important;
  line-height: inherit !important;
}

.ps-breadcrumb li{
  color: rgba(0,0,0,.87);
}

.ps-select-filter .mdc-text-field--filled:not(.mdc-text-field--disabled),
.ps-select-filter .mdc-text-field--filled.mdc-text-field--disabled,
.ps-select-filter .mdc-text-field--filled:not(.mdc-text-field--disabled):hover,
.ps-select-filter .mdc-text-field--filled.mdc-text-field--disabled:hover,
.ps-select-filter .mdc-text-field--filled:not(.mdc-text-field--disabled):focus,
.ps-select-filter .mdc-text-field--filled.mdc-text-field--disabled:focus{
  background-color:inherit !important;
}

.ps-select-filter .mat-mdc-form-field-focus-overlay{
  opacity: 0 !important;
}

