.lgpd-layout-column{
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  color: rgba(0, 0, 0, 0.87);

  &.center {
    align-content: center;
    align-items: center;
  }
}

.lgpd-consent-hidden-input{
  display: none;
}

.lgpd-layout-row{
  flex-direction: row;
  box-sizing: border-box;
  display: flex;

  margin-bottom: 1rem;

  &.center {
    align-content: center;
    align-items: center;
  }

  p {
    margin: 0;
  }
}

.lgpd-layout-flex{
  flex: 1 1 0%;
  box-sizing: border-box;

  &.center {
    display: flex;
    align-content: center;
    align-items: center;
  }
}

.lgpd-label-checkbox {
  margin: 0 0 0 5px;

  p {
    margin: 0;
  }
}

.order-6 {
  order: 6;
}
